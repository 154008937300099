/* global OFFLINE_ASSETS_MANIFEST */

// NOTE: rev hashed files to precache from build will be included as global `const OFFLINE_ASSETS_MANIFEST = {   }`
import { CacheExpiration } from 'workbox-expiration'
import logger from '../lib/logger'
import {
  getMirrorDb,
  getOfflineAssetsDb,
  MIRROR_ACTIVE,
  MIRROR_DB_STORE,
  MIRROR_DEFAULT_PRIORITY,
  MIRROR_PRIMARY,
  MIRROR_UP,
} from './_includes/components/idb-storage/idb-storage'
import { getNormalizedPath } from '../lib/get-normalized-path'

import { addMirror, fetchFromNetwork } from './sw/networking.js'

const CORE_CACHE_NAME = 'core-cache'
const HTML_CACHE_NAME = 'html-cache'
const CONTENT_BUNDLE_CACHE = 'content-bundle'
const CORE_ASSETS = Object.keys(OFFLINE_ASSETS_MANIFEST.coreAssets)
const htmlExpirationManager = new CacheExpiration(
  HTML_CACHE_NAME,
  {
    maxEntries: 10,
  }
)
const FALLBACK_IMAGE = 'assets/fallback/general.svg'

const offlineAssets = [
  { id: 'mirrors', expiryDays: 10, refreshHandler: updateMirrors },
  { id: 'content-bundle', expiryDays: 1, refreshHandler: fetchContentBundle },
  { id: 'core-assets', expiryDays: 1, refreshHandler: updateCoreAssets },
  { id: 'bookmarks', expiryDays: 1, refreshHandler: updateBookmarks },
]

/**
 * Register a service worker `install` event listener
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers
 *   for more info on service worker lifecycle and events
 */
self.addEventListener('install', (event) => {
  logger('Service worker installing')
  event.waitUntil(
    saveMirrorSources(OFFLINE_ASSETS_MANIFEST.mirrors).then(() => {
      return Promise.all(offlineAssets.map(offlineAsset => offlineAsset.refreshHandler()))
        .then(() => self.skipWaiting())
    })
  )
})

/**
 * Register a service worker `activate` event listener
 */
self.addEventListener('activate', (event) => {
  logger('Service worker activating')
  event.waitUntil(
    caches.open(CORE_CACHE_NAME).then(cache => {
      return cache.keys().then(entries => {
          return Promise.all(
            entries
              .filter(request => !CORE_ASSETS.includes(getNormalizedPath(request)))
              .map(cacheName => cache.delete(cacheName))
          )
        }
      ).then(() => self.clients.claim())
    })
  )
})

/**
 * Register a service worker `fetch` event listener; event and request objects are documented here:
 * - https://developer.mozilla.org/en-US/docs/Web/API/FetchEvent/request
 * - https://developer.mozilla.org/en-US/docs/Web/API/FetchEvent
 *
 * Proxying can be disabled by setting `SERVICE_WORKER_PROXY_DISABLED=true` in the environment
 */
self.addEventListener('fetch', async (event) => {
  // Disable proxying if set in .env
  if (process.env.SERVICE_WORKER_PROXY_DISABLED === 'true') {
    return
  }

  const { request } = event
  const path = getNormalizedPath(request)

  // ignore previews from mirroring
  if (path.includes('/preview/')) {
    return
  }

  event.waitUntil(updateOfflineAssets())

  if (isCoreGetRequest(request)) {
    event.respondWith(
      caches
        .open(CORE_CACHE_NAME)
        .then(cache => cache.match(path))
        .then(response => response ? response : fetchAndCache(path, CORE_CACHE_NAME))
    )
  } else if (isHtmlGetRequest(request)) {
    event.respondWith(
      caches
        .match(path)
        .then(response => {
          if (response) {
            // Cache hit
            const staleEtag = removeWeakDirective(response.headers.get('ETag'))
            const staleTimeCached = parseInt(response.headers.get('x-time-cached'))
            // Revalidate the cached response with a fresh one. `event.waitUntil` semantics:
            // https://developer.mozilla.org/en-US/docs/Web/API/ExtendableEvent/waitUntil
            event.waitUntil(
              fetchAndCache(path, HTML_CACHE_NAME, true).then(async response => {
                await htmlExpirationManager.updateTimestamp(request.url)
                await htmlExpirationManager.expireEntries()
                const newEtag = removeWeakDirective(response.headers.get('ETag'))
                if (staleEtag && staleEtag !== newEtag) {
                  const message = {
                    type: 'stale-content',
                    url: request.url,
                    timeCached: staleTimeCached,
                  }
                  postMessage(message)
                }
              })
            )
            return response
          } else {
            // Cache miss. Let's wait for the network instead!
            logger('service-worker.js (fetch): Nothing found in cache, fetching from network instead')
            return fetchAndCache(path, HTML_CACHE_NAME, true)
              .then(response => { return response })
          }
        })
        .catch(error => {
          // Offline fallback path
          logger('service-worker.js (fetch): All mirrors failed, returning offline fallback', error)
          return caches
            .open(CORE_CACHE_NAME)
            .then(cache => cache.match('/bookmarks/index.html'))
        })
    )
  } else if (isSameOriginGetRequest(request)) {
    event.respondWith(fetchFromNetwork(path))
  } else if (isImageRequest(request)) {
    event.respondWith(
      fetch(request)
        .catch(() => {
          logger('service-worker.js (fetch): Image loading failed, returning placeholder')
          return caches.match(FALLBACK_IMAGE)
        })
    )
  }
})

/**
 * Register a service worker `push` event listener;
 */
self.addEventListener('push', (event) => {
  const pushMessageData = event.data.json()
  logger('Received push event with data: ', pushMessageData)
  const promiseChain = []

  if (pushMessageData.data && pushMessageData.data.mirrors) { // update mirrors push event
    const { mirrors } = pushMessageData.data
    promiseChain.push(saveMirrorSources(mirrors))
  }

  const notificationTitle = pushMessageData.notification.title || 'New mirrors update'
  const notificationOptions = {
    body: pushMessageData.notification.body || 'We have updated new mirrors for our website',
    icon: 'assets/icons/install-icon-512x512', // leading slash should be left out because of path based mirrors
    badge: 'assets/icons/logo-monochrome.svg', // same as above
    data: pushMessageData.data,
    dir: 'rtl',
  }

  promiseChain.push(self.registration.showNotification(notificationTitle, notificationOptions))

  event.waitUntil(
    Promise.all(promiseChain)
  )
})

/**
 * Register a service worker `notificationclick` event listener;
 */
self.addEventListener('notificationclick', (event) => {
  const notification = event.notification
  const notificationData = notification.data.data

  notification.close()

  const urlToOpen = notificationData.url
    ? notificationData.url
    : new URL('/', self.location.origin).href
  const onNotificationClickPromise = self.clients.matchAll({
    type: 'window',
    includeUncontrolled: true
  })
    .then((windowClients) => {
      let matchingClient = null

      for (let i = 0; i < windowClients.length; i++) {
        const windowClient = windowClients[i]
        if (windowClient.url === urlToOpen) {
          matchingClient = windowClient
          break
        }
      }

      if (windowClients.length) {
        if (matchingClient) { // open client with mathching url
          return matchingClient.focus()
        } else { // open client without matching url
          return windowClients[0].navigate(urlToOpen)
        }
      } else { // no open client, open new window
        self.clients.openWindow(urlToOpen)
      }
    })

  event.waitUntil(onNotificationClickPromise)
})

/*********************
 * UTILITY FUNCTIONS *
 *********************/

// Networking / `fetch`

/**
 * Fetch a request from the network an save it in cache storage
 *
 * @param {Object} path               The request path
 * @param {String} cacheName          The unique cache key in cache storage
 * @param {Boolean} isDocumentFetch   Boolean representing if the request is a document(HTML) request
 * @returns {Promise}             Resolves with response object
 */
function fetchAndCache(path, cacheName, isDocumentFetch = false) {
  return fetchFromNetwork(path, isDocumentFetch).then(response => {
    if (response.ok) {
      return caches
        .open(cacheName)
        .then(cache => cache.put(path, response.clone()))
        .then(() => response)
    } else {
      return response
    }
  })
}

async function updateOfflineAssets() {
  if (await isPrimaryMirrorDown()) {
    const database = await getOfflineAssetsDb()

    return Promise.all(offlineAssets.map(async offlineAsset => {
      // check if stored assets are outdated as we can not assume the install event has fired
      const cachedVersion = await database.get('offline-assets', offlineAsset.id)
      if (cachedVersion) {
        const now = new Date().getTime()
        if (now < cachedVersion.expiryDate) {
          // offline asset is up to date, no need to fetch, just return
          return
        }
      }

      await offlineAsset.refreshHandler()
      const expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + offlineAsset.expiryDays)
      return database.put('offline-assets', {id: offlineAsset.id, expiryDate: expiryDate.getTime()})
    }))
  }

  return Promise.resolve()
}

async function updateCoreAssets() {
  if (await isPrimaryMirrorDown()) {
    return fetchFromNetwork('/offline-assets.json')
      .then(res => res.json())
      .then(offlineAssets => {
        return Promise.all(Object.keys(offlineAssets.coreAssets).map(asset => {
          return fetchAndCache(asset, CORE_CACHE_NAME)
        }))
      })
  } else {
    return caches.open(CORE_CACHE_NAME).then(cache => cache.addAll(Object.keys(OFFLINE_ASSETS_MANIFEST.coreAssets)))
  }
}

// Mirror management

/**
 * Fetches mirror definitions (json) and iterative adds mirrors to the networking library
 */
async function updateMirrors() {
  if (await isPrimaryMirrorDown()) {
    return fetchFromNetwork('/offline-assets.json')
      .then(response => response.json())
      .then(offlineAssets => saveMirrorSources(offlineAssets.mirrors))
  } else {
    return saveMirrorSources(OFFLINE_ASSETS_MANIFEST.mirrors)
  }
}

async function updateBookmarks() {
  return caches.open('bookmarks')
    .then(async cache => {
      const bookmarkedRequests = await cache.keys()
      return Promise.all(bookmarkedRequests.map(request => {
        return fetchAndCache(request.url, 'bookmarks', true)
      }))
    })
}

async function isPrimaryMirrorDown() {
  const mirrorDb = await getMirrorDb()
  const tx = mirrorDb.transaction(MIRROR_DB_STORE)
  const index = tx.store.index('nextAvailable')
  const primaryMirrorIsDown = (await index.count(IDBKeyRange.bound([MIRROR_UP, MIRROR_PRIMARY], [MIRROR_ACTIVE, MIRROR_PRIMARY]))) === 0

  return primaryMirrorIsDown
}

async function fetchContentBundle() {
  if (await isPrimaryMirrorDown()) {
    const contentBundleEntries = await fetchFromNetwork('/offline-assets.json')
      .then(response => response.json())
      .then(offlineAssets => offlineAssets.contentBundle)

    return Promise.all([
      invalidateContentBundleCache(contentBundleEntries),
      saveContentBundle(contentBundleEntries)
    ])
  } else {
    return Promise.all([
      saveContentBundle(OFFLINE_ASSETS_MANIFEST.contentBundle),
      invalidateContentBundleCache(OFFLINE_ASSETS_MANIFEST.contentBundle)
    ])
  }
}

function invalidateContentBundleCache(entries) {
  return caches.open(CONTENT_BUNDLE_CACHE)
    .then(cache => {
      return cache.keys()
        .then(keys => {
          return Promise.all(keys
            .filter(key => !entries.includes(key))
            .map(outdatedEntry => cache.delete(outdatedEntry))
          )
        })
    })
}

async function saveContentBundle(entries) {
  const urls = entries.map(entry => {
    const request = new Request(entry)
    return getNormalizedPath(request)
  })

  return Promise.all(urls.map(url => fetchAndCache(url, CONTENT_BUNDLE_CACHE, true)))
}

/**
 * Saves mirrors to indexedDb
 *
 * @param {Array} mirrors A list of mirrors
 * @returns {Promise}
 */
async function saveMirrorSources(mirrors) {
  // delete all mirrors
  const mirrorDb = await getMirrorDb()
  const tx = mirrorDb.transaction(MIRROR_DB_STORE, 'readwrite')
  const store = tx.objectStore(MIRROR_DB_STORE)
  await store.clear()

  return Promise.all(mirrors.map(mirror => {
    const last_modified = Date.now()
    const status = MIRROR_ACTIVE
    const priority = mirror.isPrimary ? MIRROR_PRIMARY : MIRROR_DEFAULT_PRIORITY
    return addMirror(mirror.id, last_modified, status, priority)
  }))
}

// Notifications / `push`

/**
 * Sends a postmessage to all clients
 *
 * @param  {Object} message object
 * #returns {Promise}
 */
function postMessage(message) {
  return self.clients.matchAll().then(clients => {
    return Promise.all(
      clients.map(client => {
        return Promise.resolve(client.postMessage(JSON.stringify(message)))
      })
    )
  })
}

// Utilities

/**
 * Checks if a request is a core GET request
 *
 * @param {Object} request		The request object
 * @returns {Boolean}			    Boolean value indicating whether the request url is in the core mapping
 */
function isCoreGetRequest(request) {
  return (request.method === 'GET' && CORE_ASSETS.includes(new URL(request.url).pathname))
}

/**
 * Checks if a request is a GET request for an HTML resource
 *
 * @param {Object} request		The request object
 * @returns {Boolean}			    Boolean value indicating whether the request url is in the core mapping
 */
function isHtmlGetRequest(request) {
  return (request.method === 'GET' && request.headers.get('accept') !== null && request.headers.get('accept').indexOf('text/html') > -1)
}

/**
 * Checks if a request is a GET request for resource in the service worker scope (same origin)
 *
 * @param {Object} request		The request object
 * @returns {Boolean}			    Boolean value indicating whether the request url is in the core mapping
 */
function isSameOriginGetRequest(request) {
  return (request.method === 'GET' && new URL(request.url).origin === new URL(self.registration.scope).origin)
}

/**
 * Checks if a request is a GET request for an image resource
 *
 * @param {Object} request           The request object
 * @returns {Boolean}                Boolean value indicating whether the request for an image
 */
function isImageRequest(request) {
  return request.destination === 'image'
}

/**
 * Convert a weak ETag by stripping W/
 *
 * @param {String} etag    A weak or strong etag string
 * @returns {String}       The Etag with the weak directive W/ removed
 */
function removeWeakDirective(etag) {
  /* Cloudflare converts a strong etag returned from the backend storage into a weak etag.
   * If we want to be able to compare strong and weak etags on the front-end, we need to
   * strip the weak etag directive `W/`.
   */
  return etag && etag.replace(/^W\//, '')
}
