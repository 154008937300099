import { openDB, deleteDB } from 'idb'

const MIRROR_DB = 'more-mirrors-1'
const MIRROR_DB_VERSION = 1

export const MIRROR_DB_STORE = 'sources'

export const MIRROR_UP = 0 // 'up'
export const MIRROR_ACTIVE = 1 // 'active'
export const MIRROR_LIE_FI = 2
export const MIRROR_DOWN = 3

export const MIRROR_PRIMARY = 0
export const MIRROR_DEFAULT_PRIORITY = 4
export const MIRROR_LOWEST_PRIORITY = 7

/**
 * Here's what an object in my object store looks like:
 *  {
 *    url: <String>,
 *    last_modified: <Number>,
 *    status: <Number>,
 *    priority: <Number>,
 *  }
 */
export async function getMirrorDb() {
  return await openDB(MIRROR_DB, MIRROR_DB_VERSION, {
    upgrade(db, oldVersion) {
      if (oldVersion < 1) {
        // Remove old database `mirrors`, `more-mirrors` and `visitor-db`
        deleteDB('mirrors')
        deleteDB('more-mirrors')
        deleteDB('visitor-id')
        // Create new database model
        const statusStore = db.createObjectStore(MIRROR_DB_STORE, {
          keyPath: 'url',
        })
        statusStore.createIndex('status', 'status')
        statusStore.createIndex('nextAvailable', ['status', 'priority'])
        statusStore.createIndex('lastModified', 'last_modified')
      }
    },
  })
}

export async function getAnalyticsDb() {
  return openDB('analytics', 1, {
    upgrade(db) {
      db.createObjectStore('events', { autoIncrement: true })
    },
  })
}

export async function getOfflineAssetsDb() {
  return openDB('offline-assets', 1, {
    upgrade(db) {
      db.createObjectStore('offline-assets', { keyPath: 'id'})
    },
  })
}
