import logger from '../../lib/logger'

import {
  getMirrorDb,
  getAnalyticsDb,
  MIRROR_DB_STORE,
  MIRROR_UP,
  MIRROR_ACTIVE,
  MIRROR_LIE_FI,
  MIRROR_DOWN,
  MIRROR_PRIMARY,
  MIRROR_DEFAULT_PRIORITY,
  MIRROR_LOWEST_PRIORITY,
} from '../_includes/components/idb-storage/idb-storage'

const MIRROR_STATUS_EXPIRATION = 10 * 60 * 1000 // in millis
const REQUEST_EXPIRATION = 3000 // in millis

/**
 * Adds or updates a mirror to my available mirrors in idb
 *
 * @param url
 * @param last_modified
 * @param status
 * @param priority
 * @returns {Promise}
 */
export async function addMirror(url, last_modified, status, priority) {
  const db = await getMirrorDb()
  await db.put(MIRROR_DB_STORE, {
    url,
    last_modified: last_modified ?? Date.now(),
    status: status ?? MIRROR_ACTIVE,
    priority: priority ?? MIRROR_DEFAULT_PRIORITY,
  })
}

async function mark(mirror, withStatus, telemetry) {
  const db = await getMirrorDb()
  const tx = db.transaction(MIRROR_DB_STORE, 'readwrite')
  const values = await tx.store.get(mirror)
  const promise = Promise.all([
    tx.store.put({
      ...values,
      last_modified: Date.now(),
      status: withStatus,
    }),
    tx.done,
  ])
  if (process.env.ANALYTICS_ENABLED === 'true' && telemetry) {
    try {
      if (withStatus === MIRROR_UP && values.status > MIRROR_UP) {
        const analyticsDb = await getAnalyticsDb()
        const oldStatus = values.status === MIRROR_DOWN ? 'down' : 'lie-fi'
        analyticsDb.put('events', {
          category: 'mirrors',
          action: `up (was ${oldStatus})`,
          name: `${mirror}`,
        })
      } else if (withStatus > MIRROR_ACTIVE && values.status === MIRROR_UP) {
        const analyticsDb = await getAnalyticsDb()
        const newStatus = withStatus === MIRROR_DOWN ? 'down' : 'lie-fi'
        analyticsDb.put('events', {
          category: 'mirrors',
          action: `${newStatus} (was up)`,
          name: `${mirror}`,
        })
      }
    } catch (e) {
      // let this fail silently, so that the request handling doesn't completely fail
      logger('Unable to save mirror status analytics event', e)
    }
  }
  return promise
}

async function nextAvailableMirror() {
  const db = await getMirrorDb()
  const tx = db.transaction(MIRROR_DB_STORE)
  const index = tx.store.index('nextAvailable')
  const lowerBounds = [MIRROR_UP, MIRROR_PRIMARY]
  const upperBounds = [MIRROR_ACTIVE, MIRROR_LOWEST_PRIORITY]

  return await index.get(IDBKeyRange.bound(lowerBounds, upperBounds))
}

/**
 * Fetch the requested resource from our network of mirrors
 *
 * @returns {Promise}        Resolves with a response object
 * @param path
 * @param isDocumentFetch
 */
export async function fetchFromNetwork(path, isDocumentFetch= false) {
  // make sure path are relative when using new URL() later on by stripping the leading slash
  const relativePath = (path).replace(/^\//, '')
  let errors = []

  if (isDocumentFetch) {
    await expireMirrorStatus()
  }

  let next = await nextAvailableMirror()

  while (next) {
    const mirror = next.url
    const url = new URL(relativePath, mirror)

    const controller = new AbortController()

    try {
      const response = await Promise.race([
        timeout(REQUEST_EXPIRATION),
        fetch(url, {
          signal: controller.signal,
        }),
      ])

      // Success path

      const headers = new Headers(response.headers)
      headers.append('x-mirror', mirror)
      headers.append('x-time-cached', Date.now())

      await mark(mirror, MIRROR_UP, isDocumentFetch)

      return new Response(response.body, { ...response, headers })
    } catch (error) {
      // Fail path; either timeout or fetch error

      controller.abort()
      errors.push(error)

      if (error.status === 408) {
        await mark(mirror, MIRROR_LIE_FI, isDocumentFetch)
      } else {
        await mark(mirror, MIRROR_DOWN, isDocumentFetch)
      }

      logger('networking.js (fetchFromNetwork): Failed request on mirror ', mirror, ' with error ', error)
    }

    next = await nextAvailableMirror()
  }

  return Promise.reject(
    new Error('networking.js (fetchFromNetwork): Out of mirrors.', {
      cause: errors,
    })
  )
}

async function expireMirrorStatus() {
  const now = Date.now()
  const db = await getMirrorDb()

  const txs = db.transaction(MIRROR_DB_STORE)
  const index = txs.store.index('status')
  const lowerBounds = [MIRROR_UP]
  const upperBounds = [MIRROR_ACTIVE]
  const allMirrorsDown = (await index.count(IDBKeyRange.bound(lowerBounds, upperBounds))) === 0

  const txu = db.transaction(MIRROR_DB_STORE, 'readwrite')
  let cursor = await txu.store.openCursor()

  while (cursor) {
    const value = cursor.value
    if (allMirrorsDown) {
      cursor.update({ ...value, last_modified: now, status: MIRROR_ACTIVE })
    } else if (value.status > MIRROR_ACTIVE && value.last_modified < now - MIRROR_STATUS_EXPIRATION) {
      cursor.update({ ...value, last_modified: now, status: MIRROR_ACTIVE })
    }
    cursor = await cursor.continue()
  }
}

/**
 * Return a 408 Request Timeout Response object after `delay` millis
 *
 * @param {int} delay  Duration (in millis) for the request to time out
 * @returns {Promise}  Always rejects with 408
 */
function timeout(delay) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(
        new Response('', {
          status: 408,
          statusText: 'Request Timeout',
        })
      )
    }, delay)
  })
}
