/**
 * Normalizes a Request object's URL field by adding / or /index.html when necessary
 *
 * @param {Request} request		The request object
 * @returns {String}		      The path of the request
 */
export function getNormalizedPath(request) {
  const url = new URL(request.url)
  const hasExt = /\.[0-9a-z]+$/

  const htmlSuffix = url.pathname.endsWith('/')
    ? 'index.html'
    : '/index.html'

  const pathname = hasExt.exec(url.pathname)
    ? url.pathname
    : url.pathname + htmlSuffix

  return pathname
}
